
.mapbox-satellite {
  overflow: hidden;
}
.satellite-control {
  width: 70px;
  height: 64px;
  background: url('/images/satellite-control.jpg') no-repeat left top;
  display: flex;
  align-items: flex-end;
  cursor: pointer;
  &.street {
    background-position: -200px 0;
  }
}

.satellite-control-text {
  padding: 4px 0;
  color: white;
  font-size: 14px;
  width: 100%;
  text-align: center;
  background: -webkit-linear-gradient(top, transparent ,rgba(0, 0, 0, .8));
}