.search-row {
  &.active,
  &:hover {
    background-color: var(--color-theme-light);
    mark {
      color: unset;
    }
    span {
      color: var(--color-theme);
    }
  }
  mark {
    background-color: transparent;
    color: var(--font-primary-color);
  }
}