.content {
  color: #666;
  font-size: 16px;
  line-height: 20px;
  p + p {
    margin-top: 24px;
  }
  * {
    max-width: 100%;
  }
}